// src/LandingPage.js
import React from 'react';
import logo from './okoutsompolis_logo.png'; // Make sure to have a logo image in the src directory
import './index.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <img src={logo} alt="Logo" className="logo" />
      <h1>Coming Soon ...⌛</h1>
    </div>
  );
};

export default LandingPage;
